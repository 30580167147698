<template>
	<section class="mt-0">
		<validation-observer ref="refForm">
			<b-form
				@submit.prevent="onSubmit"
				@reset.prevent="resetForm"
			>
				<b-row>
					<b-col md="12">
						<b-card
							footer-tag="footer"
							class="mb-0"
						>
							<b-row>
								<b-col
									cols="12"
									class="my-1"
								>
									<b-alert
										variant="info"
										show
									>
										<h6 class="alert-heading">
											<feather-icon
												icon="InfoIcon"
												class="mr-50"
											/>
											<span
												class="small"
											>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
										</h6>
									</b-alert>
								</b-col>

								<!-- TENDER TITLE -->
								<b-col
									cols="12"
									class="mb-2"
								>
									<label for="name">
										<strong>OBJETO DEL CONTRATO</strong>
									</label>
									<validation-provider
										#default="{ errors }"
										rules="required"
										name="objeto del contrato"
									>
										<b-form-input
											id="name"
											v-model="formData.title"
											placeholder="Ingrese el objeto del contrato ..."
											:state="errors.length > 0 ? false : null"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
											trim
										/>

										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>

								<!-- CONTRACT TYPE -->
								<b-col
									cols="12"
									md="5"
									class="mb-2"
								>
									<label for="contractType">
										<strong>TIPO DE CONTRATO</strong>
									</label>
									<validation-provider
										#default="{ errors }"
										rules="required"
										name="tipo de contrato"
									>
										<v-select
											id="contractType"
											v-model="formData.contract_type"
											:state="errors.length > 0 ? false : null"
											:options="options.contractTypes"
											placeholder="Seleccione"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
										>
											<template #option="{ label, description, code }">
												{{ label }}
												<br />
												<cite>{{ description }} | Código: {{ code }}</cite>
											</template>
										</v-select>

										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>

								<!-- LOCATION -->
								<b-col
									cols="12"
									md="4"
									class="mb-2"
								>
									<label for="location">
										<strong>EJECUCIÓN DE SERVICIO</strong>
									</label>
									<validation-provider
										#default="{ errors }"
										rules="required"
										name="rubro"
									>
										<v-select
											id="location"
											v-model="formData.locations"
											:state="errors.length > 0 ? false : null"
											multiple
											:options="options.locations"
											placeholder="Seleccione"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
										/>

										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>

								<b-col
									cols="12"
									md="3"
									class="mb-2"
								>
									<label for="tender_type">
										<strong>TIPO DE LICITACIÓN</strong>
									</label>
									<validation-provider
										#default="{ errors }"
										rules="required"
										name="Tipo de licitación"
									>
										<v-select
											id="tender_type"
											v-model="formData.tender_type"
											:state="errors.length > 0 ? false : null"
											:options="options.tenderTypes"
											placeholder="Seleccione"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
										/>

										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>

								<!-- DESCRIPTION -->
								<b-col
									cols="12"
									class="mb-2"
								>
									<label for="description">
										<strong>DESCRIPCIÓN</strong>
									</label>
									<validation-provider
										#default="{ errors }"
										rules="required"
										name="descripción"
									>
										<b-form-textarea
											id="description"
											v-model="formData.description"
											placeholder="Ingrese los detalles ..."
											rows="5"
											:state="(errors.length > 0 ? false:null)"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
											trim
										></b-form-textarea>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>

								<!-- VISIT -->
								<b-col
									cols="12"
									class="mb-2"
								>
									<b-form-checkbox
										:checked="formData.enabled_visit"
										@change="handleCheckboxChange"
										:disabled="requestStateId !== EN_EDICION"
										:readonly="requestStateId !== EN_EDICION"
									>HABILITAR VISITA</b-form-checkbox>
								</b-col>

								<!-- VISIT DOCUMENTS -->
								<b-col
									cols="12"
									v-if="formData.enabled_visit"
								>
									<b-input-group class="mb-1">
										<b-form-input
											v-model="newDocument"
											placeholder="Ingrese nuevo documento y presione ENTER o click al botón AGREGAR"
											@keydown.enter.prevent="addDocument"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
											trim
										/>
										<b-input-group-append>
											<b-button
												@click="addDocument"
												variant="outline-primary"
												:disabled="requestStateId !== EN_EDICION"
											>Agregar</b-button>
										</b-input-group-append>
									</b-input-group>

									<label
										for="visitDocuments"
										class="mb-1"
									>
										<strong>DOCUMENTOS REQUERIDOS PARA LA VISITA</strong>
									</label>
									<div
										class="d-flex align-items-center mb-1"
										v-for="(document, index) in formData.documents"
										:key="index"
									>
										<b-form-checkbox
											name="documents"
											:checked="document.is_required"
											@change="toggleRequired(document)"
											:disabled="requestStateId !== EN_EDICION"
											:readonly="requestStateId !== EN_EDICION"
										>{{ document.name }}</b-form-checkbox>
										<div
											class="d-flex align-items-center text-nowrap"
											v-if="document.is_new"
										>
											<feather-icon
												:id="`document-${index}`"
												v-if="requestStateId === EN_EDICION"
												icon="XIcon"
												class="cursor-pointer text-danger"
												style="margin-left: 10px"
												size="16"
												@click.stop="removeDocument(index)"
											/>
											<b-tooltip
												v-if="requestStateId === EN_EDICION"
												variant="danger"
												placement="right"
												triggers="hover"
												title="Eliminar"
												class="cursor-pointer"
												:target="`document-${index}`"
											/>
										</div>
									</div>
								</b-col>
							</b-row>

							<template #footer>
								<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
									<!-- BUTTON SUBMIT -->
									<b-button-loading
										v-if="requestStateId === EN_EDICION"
										text="GUARDAR CAMBIOS"
										type="submit"
										class="order-sm-2 order-1 ml-sm-2 ml-0"
										:processing="processing"
									/>
								</b-col>
							</template>
						</b-card>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</section>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { RequestStates } from "@core/utils/data"
import { ref, onMounted, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email, integer, min } from "@validations"
import useNotifications from "@notifications"
import generalInformationStoreModule from "./generalInformationStoreModule"
import Ripple from "vue-ripple-directive"
import { VBTooltip } from "bootstrap-vue"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BButtonLoading,

		required,
		email,
		integer,
		min,

		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			required: true
		},
		requestInformationId: {
			type: Number,
			required: true
		},
		visitDocuments: {
			type: Array,
			default: () => [],
			required: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	setup(props, { emit }) {
		// USE TOAST
		const { toastNotification } = useNotifications()
		const { EN_EDICION } = RequestStates

		// REFS
		const refForm = ref(null)
		const options = ref({})
		const processing = ref(false)
		const newDocument = ref(null)
		const deletedDocuments = ref([])
		const formData = ref({
			title: null,
			description: null,
			enabled_visit: 0,
			is_published: 0,
			tender_type: null,
			documents: [],
			locations: []
		})

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-info"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				generalInformationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		onMounted(async () => {
			await getSelectFilters()
			getData()
		})

		const getSelectFilters = async () => {
			const response1 = await axios.get("/selectors/contract-types")
			const response2 = await axios.get("/selectors/locations")
			const tenderTypes = [
				{ label: "NORMAL", value: "NORMAL" },
				{ label: "DIRECTA", value: "DIRECTA" }
			]
			options.value = {
				...response1.data,
				...response2.data,
				tenderTypes
			}
		}

		const setData = (data) => {
			const contract_type = options.value.contractTypes.find(
				(item) => item.value === data.contract_type_id
			)
			const tender_type = options.value.tenderTypes.find(
				(item) => item.value === data.tender_type
			)

			const locationIds = data.locations.map((item) => item.value)
			const locations = options.value.locations.filter((item) =>
				locationIds.includes(item.value)
			)

			formData.value = {
				title: data.title,
				description: data.description,
				enabled_visit: data.enabled_visit,
				documents: props.visitDocuments,
				contract_type,
				tender_type,
				locations
			}
		}

		const getData = () => {
			store
				.dispatch("app-request-info/fetchRequestInformation", {
					id: props.requestInformationId
				})
				.then((response) => {
					setData(response.data.requestInformation)
				})
				.catch((error) => {
					const message = error.message || error.response.data.message
					toastNotification(message, false)
				})
		}

		const addDocument = () => {
			if (newDocument.value) {
				formData.value.documents.push({
					name: newDocument.value.toUpperCase(),
					is_required: 1,
					is_new: 1
				})
			}

			newDocument.value = null
		}

		const removeDocument = (index) => {
			const document = formData.value.documents[index]
			if (document.id) {
				deletedDocuments.value.push(document)
			}

			formData.value.documents.splice(index, 1)
		}

		const toggleRequired = (document) => {
			document.is_required = !document.is_required
		}

		const handleCheckboxChange = (checked) => {
			if (checked) {
				formData.value.enabled_visit = true
			} else {
				formData.value.enabled_visit = false
				formData.value.documents = formData.value.documents.map((item) => ({
					...item,
					is_required: 0
				}))
			}
		}

		const onSubmit = async () => {
			processing.value = true

			const isValid = await refForm.value.validate()

			if (!isValid) {
				processing.value = false
				return toastNotification("Complete los campos requeridos", false)
			}
			if (!formData.value.locations.length) {
				processing.value = false
				return toastNotification(
					"Debe seleccionar al menos un lugar de ejecuición de servicio",
					false
				)
			}
			if (formData.value.enabled_visit) {
				const haveRequired = formData.value.documents.some(
					(item) => item.is_required
				)
				if (!haveRequired) {
					processing.value = false
					return toastNotification(
						"Debe seleccionar al menos un documento para la visita",
						false
					)
				}
			}

			const locations = formData.value.locations.map((item) => item.value)

			const payload = {
				locations,
				request_id: props.requestId,
				title: formData.value.title,
				contract_type_id: formData.value.contract_type.value,
				tender_type: formData.value.tender_type.value,
				description: formData.value.description,
				enabled_visit: formData.value.enabled_visit,
				selectedDocuments: formData.value.documents,
				deletedDocuments: deletedDocuments.value,
				published: false,
				fromParams: true
			}

			store
				.dispatch("app-request-info/updateRequestInformation", {
					id: props.requestInformationId,
					payload
				})
				.then((response) => {
					toastNotification(response.data.message)
				})
				.catch((error) => {
					const message =
						error.message ||
						error.response?.data?.message ||
						"Error al actualizar la información"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			processing,
			formData,
			options,

			// REFS
			refForm,
			newDocument,

			// METHODS
			setData,
			addDocument,
			removeDocument,
			toggleRequired,
			handleCheckboxChange,
			onSubmit,

			EN_EDICION
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>