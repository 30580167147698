<template>
	<section>
		<validation-observer
			#default="{ handleSubmit }"
			ref="refEconomicForm"
		>
			<b-form @submit.prevent="handleSubmit(updateData)">
				<b-card
					class="mb-0"
					no-body
				>
					<b-row>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="name">
								<strong>MONEDA</strong>
							</label>
							<validation-provider
								#default="{ errors, validate }"
								rules="required"
								name="Moneda"
							>
								<v-select
									id="moneda"
									v-model="formData.currency_type"
									:options="options.currencyTypes"
									@input="changeCurrencyType($event, validate)"
									:state="errors.length > 0 ? false : null"
									placeholder="Seleccione"
									:disabled="requestStateId !== EN_EDICION"
									:readonly="requestStateId !== EN_EDICION"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="tipo">
								<strong>TIPO</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								name="Tipo"
								rules="required"
							>
								<v-select
									id="tipo"
									v-model="formData.indicator"
									:options="options.indicators"
									:state="errors.length > 0 ? false : null"
									placeholder="Seleccione"
									:disabled="requestStateId !== EN_EDICION"
									:readonly="requestStateId !== EN_EDICION"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="gasto">
								<strong>GASTO GENERAL (GG %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Gasto General"
							>
								<b-form-input
									id="gasto"
									v-model="formData.general_expenses"
									type="number"
									step="any"
									placeholder="0.00"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									:disabled="requestStateId !== EN_EDICION"
									:readonly="requestStateId !== EN_EDICION"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="profit">
								<strong>UTILIDAD (UTI %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Utilidad"
							>
								<b-form-input
									id="profit"
									v-model="formData.profit"
									type="number"
									step="any"
									placeholder="0.00"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									:disabled="requestStateId !== EN_EDICION"
									:readonly="requestStateId !== EN_EDICION"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="tc">
								<strong>TC de SS</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="double|min:0.0"
								name="Tipo de cambio"
							>
								<b-form-input
									id="tc"
									v-model="formData.exchange_rate"
									@input="calculateStatistics()"
									type="number"
									placeholder="0.0"
									:state="errors.length > 0 ? false : null"
									:disabled="isTcDisabled || requestStateId !== EN_EDICION"
									:readonly="requestStateId !== EN_EDICION"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="moneda_ss">
								<strong>MONEDA SS</strong>
							</label>

							<b-form-input
								id="moneda_ss"
								placeholder="-"
								:value="formData.exchange_sign"
								readonly
								disabled
							/>
						</b-col>

						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="secondary"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>Registro de partidas
								</h6>
								<div class="alert-body">
									<ul class="mb-0 pl-1">
										<li class="small">
											Descargar
											<b-link
												class="text-primary"
												href="/plantilla_de_partidas.xlsx"
											>
												<strong>plantilla de partidas</strong>
											</b-link>
										</li>
										<li class="small">
											Cargar la plantilla con las partidas registradas para calcular el
											<strong>importe total</strong>
										</li>
										<li class="small">Para actualizar partidas, modifique el mismo archivo y vuelva a cargar</li>
									</ul>
								</div>
							</b-alert>
						</b-col>

						<b-col cols="12">
							<label for="templateFile">
								<strong>CARGAR PLANTILLA DE PARTIDAS</strong>
							</label>
							<b-form-file
								v-model="file"
								placeholder="Seleccione un archivo o suéltelo aquí..."
								accept=".xlsx, .xls"
								drop-placeholder="Suelte archivo aquí ..."
								:disabled="requestStateId !== EN_EDICION"
								:readonly="requestStateId !== EN_EDICION"
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="formData.file_url"
						>
							<b-icon-file
								:filename="formData.original_name"
								:file-url="formData.file_url"
							/>
						</b-col>

						<b-col cols="12">
							<b-card
								no-body
								class="card-statistics mb-0"
							>
								<b-card-body class="statistics-body">
									<b-row>
										<b-col
											v-for="item in statisticsItems"
											:key="item.icon"
											md="3"
											sm="6"
											class="mb-2 mb-md-0"
											:class="item.customClass"
										>
											<b-media no-body>
												<b-media-aside class="mr-2">
													<b-avatar
														size="48"
														:variant="item.color"
													>{{ item.sign }}</b-avatar>
												</b-media-aside>
												<b-media-body class="my-auto">
													<h4 class="font-weight-bolder mb-0">{{ formatCurrency(item.amount) }}</h4>
													<b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
												</b-media-body>
											</b-media>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>

					<!-- <template #footer>
					</template>-->
					<b-col
						cols="12"
						class="mt-1"
					>
						<hr />
					</b-col>
					<b-col
						cols="12"
						class="d-flex flex-column flex-sm-row justify-content-sm-end"
					>
						<!-- BUTTON SUBMIT -->
						<b-button-loading
							v-if="requestStateId === EN_EDICION"
							text="GUARDAR CAMBIOS"
							type="submit"
							class="order-sm-2 order-1 ml-sm-2 ml-0"
							:processing="processing"
						/>
					</b-col>
				</b-card>
			</b-form>
		</validation-observer>
	</section>
</template>
  
<script>
import { ref, onMounted, onUnmounted } from "@vue/composition-api"
import { RequestStates } from "@core/utils/data"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, min } from "@validations"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import tabEconomicStoreModule from "./tabEconomicStoreModule"
import useNotifications from "@notifications"
import { formatCurrency } from "@core/utils/filter"
import vSelect from "vue-select"
import axios from "@axios"
import store from "@/store"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonUploadFile,
		BButtonLoading,
		BIconFile,

		vSelect,
		required,
		min
	},
	props: {
		requestId: {
			type: Number,
			require: true
		},
		requestEconomicId: {
			type: Number,
			require: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	setup(props, { emit }) {
		const { toastNotification } = useNotifications()
		const { EN_EDICION } = RequestStates

		//REFS
		const processing = ref(false)
		const formData = ref({})
		const refEconomicForm = ref(null)
		const file = ref(null)
		const isTcDisabled = ref(true)
		const isDolar = ref(false)
		const options = ref({})
		const statisticsItems = ref([
			{
				color: "light-primary",
				sign: "S/",
				amount: 0,
				subtitle: "COSTO DIRECTO",
				customClass: "mb-2 mb-xl-0"
			},
			{
				color: "light-info",
				amount: 0,
				sign: "S/",
				subtitle: "GASTO GENERAL",
				customClass: "mb-2 mb-xl-0"
			},
			{
				sign: "S/",
				color: "light-danger",
				amount: 0,
				subtitle: "UTILIDAD",
				customClass: "mb-2 mb-sm-0"
			},
			{
				sign: "S/",
				color: "light-success",
				amount: 0,
				subtitle: "IMPORTE TOTAL",
				customClass: ""
			}
		])

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-economic"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				tabEconomicStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-request-economic/fetchRequestEconomicTemplate", {
					id: props.requestEconomicId
				})
				.then((response) => {
					setData(response.data.economicData)
					emit("update-description", response.data.economicData.description)
				})
				.catch((error) => {
					console.log("1", error)
					const message = error.message || error.response?.data?.message
					toastNotification(message, false)
				})
		}

		const setData = (data) => {
			const indicator =
				options.value.indicators.find(
					(item) => item.value === data.indicator
				) || null
			const currency_type =
				options.value.currencyTypes.find(
					(item) => item.value === data.currency_type_id
				) || null

			formData.value = {
				file_id: data.file_id,
				original_name: data.original_name,
				file_url: data.file_url,
				currency_type,
				indicator,
				exchange_rate: data.exchange_rate,
				general_expenses: data.general_expenses
					? (parseFloat(data.general_expenses) * 100).toFixed(2)
					: 0,
				profit: data.profit ? (parseFloat(data.profit) * 100).toFixed(2) : 0,
				subtotal: data.subtotal ? parseFloat(data.subtotal) : 0,
				exchange_sign: currency_type?.sign
			}
			calculateStatistics()
		}

		onMounted(async () => {
			await getSelectFilters()
			getData()
		})

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/currency-types")

			options.value = { ...response.data }
			options.value.indicators = [
				{
					label: "PRECIO UNITARIO (PxQ)",
					value: "PRECIO UNITARIO (PxQ)"
				},
				{
					label: "SUMA ALZADA",
					value: "SUMA ALZADA"
				}
			]
		}

		const changeCurrencyType = (val) => {
			if (!val) {
				formData.value.exchange_rate = null
				formData.value.exchange_sign = null
				isTcDisabled.value = true
				isDolar.value = false
				return
			}

			if (val.label === "DÓLARES") {
				formData.value.exchange_sign = val.sign
				isTcDisabled.value = false
				isDolar.value = true
				return
			}

			formData.value.exchange_rate = null
			formData.value.exchange_sign = val.sign
			isTcDisabled.value = true
			isDolar.value = false
		}

		const calculateStatistics = () => {
			statisticsItems.value[0].amount = formData.value.subtotal
			statisticsItems.value[1].amount =
				formData.value.subtotal * (formData.value.general_expenses / 100)
			statisticsItems.value[2].amount =
				formData.value.subtotal * (formData.value.profit / 100)

			statisticsItems.value[3].amount =
				statisticsItems.value[0].amount +
				statisticsItems.value[1].amount +
				statisticsItems.value[2].amount

			if (isDolar.value) {
				statisticsItems.value[3].amount *= parseFloat(
					formData.value.exchange_rate
				)
			}
		}

		const updateData = async () => {
			processing.value = true

			const isValid = await refEconomicForm.value.validate()
			if (!isValid) {
				processing.value = false
				toastNotification("Complete los campos requeridos", false)
				return
			}

			if (isDolar.value && !parseFloat(formData.value.exchange_rate)) {
				processing.value = false
				toastNotification("Complete el tipo de cambio", false)
				return
			}

			const payload = {
				file: file.value,
				request_id: props.requestId,
				data: formData.value
			}

			store
				.dispatch("app-request-economic/updateRequestEconomicTemplate", {
					id: props.requestEconomicId,
					payload
				})
				.then((response) => {
					const { subtotal, file_url, original_name } =
						response.data.economicData

					formData.value.file_url = file_url || formData.value.file_url
					formData.value.original_name = original_name
					formData.value.subtotal = subtotal

					calculateStatistics()
					toastNotification(response.data.message)
				})
				.catch((error) => {
					const message = error.message || error.response?.data?.message
					toastNotification(message, false)
				})
				.finally(() => {
					processing.value = false
				})
		}

		return {
			//REFS
			refEconomicForm,
			formData,
			statisticsItems,
			options,
			file,
			isTcDisabled,
			processing,

			//METHODS
			calculateStatistics,
			changeCurrencyType,
			updateData,

			//HELPERS
			formatCurrency,
			EN_EDICION
		}
	}
}
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>