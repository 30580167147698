<template>
	<section>
		<b-row>
			<b-col>
				<b-card>
					<b-tabs
						content-class="mt-2"
						justified
					>
						<b-tab
							active
							title="Plantilla Económica"
						>
							<base-validation-edit-tab-economic
								:request-id="requestId"
								:request-economic-id="requestEconomicId"
								:request-state-id="requestStateId"
								@update-description="updateDescription"
							/>
						</b-tab>

						<b-tab title="Validación de Requerimientos">
							<base-validation-edit-tab-requirements
								:request-id="requestId"
								:economic-proposal="economicData"
								:request-state-id="requestStateId"
							/>
						</b-tab>

						<b-tab title="Validación de Documentos">
							<base-validation-edit-tab-documents
								:request-id="requestId"
								:request-state-id="requestStateId"
							/>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>

			<b-col cols="12">
				<b-button-loading
					v-if="requestStateId === EN_EDICION"
					text="ENVIAR SOLICITUD"
					variant="relief-primary"
					@process-action="sendRequest"
					:processing="processing"
					:block="true"
					size="lg"
				/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { ref } from "@vue/composition-api"
import { RequestStates } from "@core/utils/data"
import { getUserAccess } from "@/auth/utils"
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BaseValidationEditTabEconomic from "./tab-economic/BaseValidationEditTabEconomic.vue"
import BaseValidationEditTabRequirements from "./tab-requirements/BaseValidationEditTabRequirements.vue"
import BaseValidationEditTabDocuments from "./tab-documents/BaseValidationEditTabDocuments.vue"
import VueSweetalert2 from "vue-sweetalert2"
import router from "@/router"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		BaseValidationEditTabEconomic,
		BaseValidationEditTabRequirements,
		BaseValidationEditTabDocuments,
		BButtonLoading
	},
	props: {
		requestId: {
			type: Number,
			required: true
		},
		requestEconomicId: {
			type: Number,
			required: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()
		const { EN_EDICION } = RequestStates

		const processing = ref(false)
		const sending = ref(false)

		const economicData = ref({
			id: props.requestEconomicId,
			description: null
		})

		const updateDescription = (value) => {
			economicData.value.description = value
		}

		const sendRequest = () => {
			Vue.swal({
				title: "¿Está seguro(a) de enviar la solicitud?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (!result.value) return

				Vue.swal.fire({
					title: "Procesando...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Vue.swal.showLoading()
					}
				})

				processing.value = true

				try {
					const payload = {
						request_state_id: props.requestStateId,
						request_id: props.requestId
					}
					const response = await axios.put(
						`/requests/${props.requestId}`,
						payload
					)
					Vue.swal.close()

					router.push({ name: "requests-list" })
					swalNotification(response.data.message)
				} catch (error) {
					Vue.swal.close()
					swalNotification(error.response?.data?.message, false)
				} finally {
					processing.value = false
				}
			})
		}

		return {
			processing,
			economicData,

			updateDescription,
			sendRequest,

			EN_EDICION
		}
	}
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>