import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchRequestBidders(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/request-bidders`, { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		addRequestBidders(ctx, { payload }) {
			return new Promise((resolve, reject) => {
				axios
					.post(`/request-bidders`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateRequestBidder(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/request-bidders/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		deleteRequestBidder(ctx, { id, queryParams }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/request-bidders/${id}`, { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}