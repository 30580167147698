<template>
	<section class="mt-0">
		<b-row>
			<b-col
				cols="12"
				v-if="usersAssigned"
			>
				<statistic-card-horizontal
					icon="UsersIcon"
					color="info"
					:statistic="usersAssigned"
					statistic-title="Analista asignado para la licitación"
				/>
			</b-col>

			<b-col cols="12">
				<b-card class="p-0">
					<b-row>
						<b-col
							class="mb-2"
							cols="12"
						>
							<b-row>
								<b-col
									cols="12"
									md="6"
									class="ml-auto"
								>
									<div class="text-right">
										<b-button
											v-if="validateApprovalBtn($can('leadership', 'approvals'), $can('superintendence', 'approvals'),$can('admin', 'approvals'))"
											@click="openApprovalModal"
											variant="primary"
											:disabled="disableBtn"
										>
											<span class="text-nowrap">Evaluar solicitud</span>
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-col>

						<b-col
							cols="12"
							class="mb-2"
						>
							<b-table
								class="table-sm"
								ref="refApprovalsTable"
								:fields="fields"
								:items="items"
								empty-text="No se encontraron registros coincidentes"
								show-empty
								responsive
								bordered
								fiexed
							>
								<template #cell(status)="data">
									<b-badge
										class="text-nowrap"
										:variant="data.item.status === true ? 'light-success' : data.item.status === false ? 'light-danger' : 'light-warning'"
										pill
									>{{ data.item.status === true ? 'APROBADO' : data.item.status === false ? 'RECHAZADO' : 'PENDIENTE' }}</b-badge>
								</template>

								<template #cell(approver_fullname)="data">
									<span class="text-nowrap">{{ data.item.approver_fullname || '-' }}</span>
								</template>

								<template #cell(approved_at)="data">
									<span class="text-nowrap">{{ formatStringToDate(data.item.approved_at) }}</span>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>

		<b-modal
			ref="refApprovalModal"
			title="EVALUAR SOLICITUD"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			no-close-on-backdrop
			hide-header-close
			no-close-on-esc
			centered
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="approver">
							<strong>APROBADOR</strong>
						</label>
						<b-form-input
							id="approver"
							:value="formData.approver"
							disabled
							readonly
						/>
					</b-form-group>
				</b-col>

				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="approver">
							<strong>TIPO DE APROBACIÓN</strong>
						</label>
						<!-- v-if="formData.hasManyLevels" -->
						<v-select
							id="approver"
							v-model="formData.approval_level"
							:options="options.approvalLevels"
							placeholder="Seleccione"
							:disabled="requestStateId !== EN_APROBACION || !formData.hasManyLevels"
							:readonly="requestStateId !== EN_APROBACION || !formData.hasManyLevels"
						/>
						<!-- <b-form-input
							v-else
							id="approver"
							:value="formData.approval_level"
							disabled
							readonly
						/>-->
					</b-form-group>
				</b-col>

				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="decision">
							<strong>DECISIÓN</strong>
						</label>
						<v-select
							id="contractType"
							v-model="formData.status"
							:options="options.decisions"
							@input="changeDecision"
							placeholder="Seleccione"
							:disabled="requestStateId !== EN_APROBACION || isApprovalLevelDisabled"
							:readonly="requestStateId !== EN_APROBACION || isApprovalLevelDisabled"
						/>
					</b-form-group>
				</b-col>

				<b-col
					cols="12"
					class="my-2"
					v-if="isAssigedVisible()"
				>
					<b-form-group>
						<label for="decision">
							<strong>ASIGNAR ANALISTA A CARGO</strong>
						</label>
						<v-select
							id="contractType"
							v-model="formData.assigners"
							:options="options.analysts"
							placeholder="Seleccione"
							multiple
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<!-- <b-button
					
					@click="saveApprovement"
					variant="primary"
				>ENVIAR</b-button>-->

				<b-button-loading
					v-if="$can('leadership', 'approvals') && requestStateId === EN_APROBACION 
					|| $can('superintendence', 'approvals') && requestStateId === EN_APROBACION 
					|| $can('admin', 'approvals') || !disableBtn"
					text="ENVIAR"
					@process-action="saveApprovement"
					:processing="processing"
				/>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import { formatStringToDate } from "@core/utils/filter"
import { getUserRoles, getUserAccess } from "@/auth/utils"
import { RequestStates } from "@core/utils/data"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onMounted, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import VueSweetalert2 from "vue-sweetalert2"
import { VBTooltip, VBModal } from "bootstrap-vue"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import router from "@/router"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		ValidationProvider,
		ValidationObserver,
		StatisticCardHorizontal,
		BButtonLoading,

		vSelect
	},
	directives: {
		"b-modal": VBModal,
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			required: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()
		const { EN_APROBACION } = RequestStates
		const { isLeadership, isSuperintendence } = getUserAccess()
		const { isSuperAdmin, isManager, isAdmin } = getUserRoles()

		const refApprovalsTable = ref(null)
		const refApprovalModal = ref(null)
		const isApprovalLevelDisabled = ref(false)
		const fields = ref([])
		const items = ref([])
		const formData = ref({
			hasManyLevels: false,
			approval_levels: [],
			approval_level: null,
			approver: null,
			status: null,
			assigners: []
		})
		const processing = ref(false)
		const usersAssigned = ref("")
		const options = ref({})
		const disableBtn = ref(false)

		const getSelectFilters = async () => {
			try {
				const requestLevels = await axios.get("/selectors/approval-levels")
				const decisions = [
					{ value: 1, label: "APROBAR" },
					{ value: 0, label: "RECHAZAR" }
				]
				options.value = { ...requestLevels.data, decisions }
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const setData = (data) => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{ key: "level_name", label: "Tipo de Aprobación", ...styleColumns },
				{ key: "status", label: "Estado", ...styleColumns },
				{
					key: "approver_fullname",
					label: "aprobador",
					thClass: styleColumns.thClass
				},
				{ key: "approved_at", label: "Fecha Registro", ...styleColumns }
			]

			const userData = JSON.parse(localStorage.getItem("userData"))
			formData.value.approver = userData.fullName.toUpperCase()

			items.value = data.items
			usersAssigned.value = data.usersAssigned

			if (isSuperAdmin) {
				formData.value.hasManyLevels = true
			}

			if (isManager && (isLeadership || isSuperintendence)) {
				const levels = [isLeadership, isSuperintendence]
				const hasTwoLevels = levels.every((value) => value === true)

				if (hasTwoLevels) {
					formData.value.hasManyLevels = true
					const answerLeadership = data.items[0].status
					const answerSuperintendence = data.items[1].status

					if (answerLeadership && answerSuperintendence) {
						options.value.approvalLevels = options.value.approvalLevels.filter(
							(item) => item.value !== 3
						)
					} else if (answerLeadership) {
						options.value.approvalLevels = options.value.approvalLevels.filter(
							(item) => item.value === 2
						)
					} else {
						options.value.approvalLevels = options.value.approvalLevels.filter(
							(item) => item.value === 1
						)
					}
				} else {
					formData.value.hasManyLevels = false
					let level_id = isLeadership ? 1 : 2
					formData.value.approval_level = options.value.approvalLevels.find(
						(item) => item.value === level_id
					)

					const findStatus = data.items.find(
						(item) => item.approval_level_id === level_id
					).status

					formData.value.status = options.value.decisions.find(
						(item) => item.value === findStatus
					)

					if (formData.value.status) isApprovalLevelDisabled.value = true
				}
			}

			if (isAdmin && isLeadership) {
				formData.value.hasManyLevels = false
				formData.value.approval_level = options.value.approvalLevels.find(
					(item) => item.value === 3
				)
			}
		}

		const getData = async () => {
			try {
				const payload = { request_id: props.requestId }
				const response = await axios.get(`/request-approvals/`, {
					params: payload
				})
				setData(response.data.requestApprovals)
			} catch (error) {
				toastNotification(error.response?.data?.message, false)
			}
		}

		onMounted(async () => {
			await getSelectFilters()
			await getData()
		})

		const validateApprovalBtn = (
			approvalLeadership,
			approvalSuperintendence,
			approvalAdmin
		) => {
			if (items.value.length === 0) return false

			const answerLeadership = items.value[0].status
			const answerSuperintendence = items.value[1].status
			const answerAdmin = items.value[2].status

			if (approvalLeadership && EN_APROBACION && !answerLeadership) return true
			if (approvalSuperintendence && EN_APROBACION && !answerSuperintendence)
				return true
			if (approvalAdmin) {
				if (!answerLeadership || !answerSuperintendence) disableBtn.value = true

				if (answerAdmin) isApprovalLevelDisabled.value = true
				return true
			}
			return false
		}

		const openApprovalModal = async () => {
			if (isSuperAdmin || (isAdmin && isLeadership)) {
				await getAnalysts()
			}

			if (!disableBtn.value) {
				refApprovalModal.value.show()
			}
		}

		const changeDecision = (e) => {
			if ((isSuperAdmin || isManager) && (!e || !e.value)) {
				formData.value.assigners = []
			}
		}

		const getAnalysts = async () => {
			const response = await axios.get("/selectors/analysts")
			options.value.analysts = [...response.data.analysts]
		}

		const isAssigedVisible = () => {
			if (
				(isSuperAdmin || (isAdmin && isLeadership)) &&
				formData.value.status?.value === 1
			) {
				return true
			}
			return false
		}

		const saveApprovement = () => {
			if (!formData.value.status) {
				toastNotification("Debe seleccionar una respuesta", false)
				return
			}

			if (
				isAdmin &&
				formData.value.status?.value &&
				!formData.value.assigners.length
			) {
				toastNotification("Debe seleccionar a un analista", false)
				return
			}

			Vue.swal({
				title: "¿Está seguro(a) de su decisión?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (!result.value) return

				Vue.swal.fire({
					title: "Procesando...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Vue.swal.showLoading()
					}
				})

				processing.value = true

				try {
					const usersAssigned = formData.value.assigners?.map(
						(item) => item.value
					)
					const payload = {
						request_id: props.requestId,
						approval_level_id: formData.value.approval_level?.value,
						status: formData.value.status?.value,
						usersAssigned
					}
					const response = await axios.put(`/request-approvals/`, payload)

					refApprovalModal.value.hide()
					swalNotification(response.data.message)
					router.push({ name: "requests-list" })
				} catch (error) {
					swalNotification(error.response?.data?.message, false)
				} finally {
					Vue.swal.close()
					processing.value = false
				}
			})
		}

		return {
			refApprovalsTable,
			refApprovalModal,
			formData,
			items,
			fields,
			options,
			usersAssigned,
			isApprovalLevelDisabled,
			disableBtn,
			processing,

			openApprovalModal,
			changeDecision,
			isAssigedVisible,
			validateApprovalBtn,
			saveApprovement,

			EN_APROBACION,
			isLeadership,
			isSuperintendence,

			formatStringToDate
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>