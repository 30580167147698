<template>
	<section>
		<b-row>
			<b-col cols="12">
				<b-alert
					variant="info"
					show
				>
					<h6 class="alert-heading">
						<ul class="mb-0 pl-1">
							<li>
								<span class="small">La suma total de los pesos de las propuesta debe ser 100%</span>
							</li>
							<li>
								<span
									class="small"
								>La suma total de las categorías seleccionadas debe ser igual al peso de la propuesta</span>
							</li>
						</ul>
					</h6>
				</b-alert>
			</b-col>

			<b-col cols="12">
				<app-collapse
					type="margin"
					v-if="proposalWeightings.length > 0"
				>
					<app-collapse-item :title="proposalWeightings[0].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[0].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[0].weight }}</strong>
							</span>
						</template>

						<template #buttons>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(true, false, false)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Descripción"
								@click.stop="openDocumentModal(true, false, false)"
							>
								<feather-icon icon="FileTextIcon" />
							</b-button>
						</template>

						<div
							v-if="!formData.economic.description"
							class="text-muted"
						>Sin descripción</div>

						<div
							v-else
							v-html="formData.economic.description"
						></div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[1].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[1].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[1].weight }}</strong>
							</span>
						</template>

						<template #buttons>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(false, true, false)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Agregar"
								@click.stop="openDocumentModal(false, true, false)"
							>
								<feather-icon icon="FilePlusIcon" />
							</b-button>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.sigDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required"
								:disabled="requestStateId !== EN_EDICION"
								:readonly="requestStateId !== EN_EDICION"
								@change="toggleRequired(document)"
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weight || 0 }}</span>
							<div class="d-flex align-items-center text-nowrap">
								<span>
									<feather-icon
										:id="`document-sig-${index}-edit-icon`"
										v-if="requestStateId === EN_EDICION"
										icon="Edit2Icon"
										class="cursor-pointer text-secondary"
										style="margin-right: 5px; margin-left: 10px"
										size="14"
										@click.stop="editDocument(index, true, false)"
									/>
									<b-tooltip
										v-if="requestStateId === EN_EDICION"
										variant="secondary"
										triggers="hover"
										title="Editar"
										placement="left"
										class="cursor-pointer"
										:target="`document-sig-${index}-edit-icon`"
									/>
								</span>
								<span v-if="document.is_new">
									<feather-icon
										:id="`document-sig-${index}-delete-icon`"
										v-if="requestStateId === EN_EDICION"
										icon="TrashIcon"
										class="cursor-pointer text-secondary"
										size="14"
										@click="removeDocument(index, true, false)"
									/>
									<b-tooltip
										v-if="requestStateId === EN_EDICION"
										variant="secondary"
										triggers="hover"
										title="Eliminar"
										placement="right"
										class="cursor-pointer"
										:target="`document-sig-${index}-delete-icon`"
									/>
								</span>
							</div>
						</div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[2].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[2].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[2].weight }}</strong>
							</span>
						</template>

						<template #buttons>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(false, false, true)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-if="requestStateId === EN_EDICION"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Agregar"
								@click.stop="openDocumentModal(false, false, true)"
							>
								<feather-icon icon="FilePlusIcon" />
							</b-button>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.tecDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required"
								@change="toggleRequired(document)"
								:disabled="requestStateId !== EN_EDICION"
								:readonly="requestStateId !== EN_EDICION"
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weight || 0 }}</span>
							<div class="d-flex align-items-center text-nowrap">
								<span>
									<feather-icon
										:id="`document-tec-${index}-edit-icon`"
										v-if="requestStateId === EN_EDICION"
										icon="Edit2Icon"
										class="cursor-pointer text-secondary"
										style="margin-right: 5px; margin-left: 10px"
										size="14"
										@click.stop="editDocument(index, false, true)"
									/>
									<b-tooltip
										v-if="requestStateId === EN_EDICION"
										variant="secondary"
										placement="left"
										triggers="hover"
										title="Editar"
										class="cursor-pointer"
										:target="`document-tec-${index}-edit-icon`"
									/>
								</span>
								<span v-if="document.is_new">
									<feather-icon
										:id="`document-tec-${index}-delete-icon`"
										v-if="requestStateId === EN_EDICION"
										icon="TrashIcon"
										class="cursor-pointer text-secondary"
										size="14"
										@click="removeDocument(index, false, true)"
									/>
									<b-tooltip
										v-if="requestStateId === EN_EDICION"
										variant="secondary"
										placement="right"
										triggers="hover"
										title="Eliminar"
										class="cursor-pointer"
										:target="`document-tec-${index}-delete-icon`"
									/>
								</span>
							</div>
						</div>
					</app-collapse-item>
				</app-collapse>
			</b-col>

			<b-col
				cols="12"
				class="mt-1"
			>
				<hr />
			</b-col>

			<b-col
				cols="12"
				class="d-flex flex-column flex-sm-row justify-content-sm-end"
			>
				<!-- BUTTON SUBMIT -->
				<b-button-loading
					v-if="requestStateId === EN_EDICION"
					text="GUARDAR CAMBIOS"
					type="button"
					class="order-sm-2 order-1 ml-sm-2 ml-0"
					:processing="processing"
					@process-action="saveInformation"
				/>
			</b-col>
		</b-row>

		<b-modal
			ref="refProposalModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			size="md"
			title="ACTUALIZAR PESO DE PROPUESTA"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-row>
					<b-col
						cols="12"
						class="mb-1"
					>
						<label for="tipo">
							<strong>Peso de propuesta</strong>
						</label>
						<b-form-input
							id="tipo"
							type="number"
							v-model="weight"
							placeholder="0"
							:disabled="requestStateId !== EN_EDICION"
							:readonly="requestStateId !== EN_EDICION"
							trim
						/>
					</b-col>
				</b-row>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					v-if="requestStateId === EN_EDICION"
					@click="updateProposalWeighting"
					variant="outline-primary"
				>ACTUALIZAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>

		<b-modal
			ref="refDocumentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			:title="isEconomic ? 'ACTUALIZAR INFORMACIÓN' : `${ isEditing ? 'EDITAR' : 'AGREGAR'} CATEGORÍA`"
			:size="isEconomic ? 'lg' : 'md'"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-row>
					<template v-if="!isEconomic">
						<b-col
							cols="12"
							class="mb-1"
						>
							<label for="tipo">
								<strong>Peso de categoría</strong>
							</label>
							<b-form-input
								id="tipo"
								type="number"
								v-model="documentForm.weight"
								placeholder="0"
								:disabled="requestStateId !== EN_EDICION"
								:readonly="requestStateId !== EN_EDICION"
								trim
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="documentVisible"
						>
							<label for="tipo">
								<strong>Categoría</strong>
							</label>
							<b-form-group>
								<b-form-input
									id="basicInput"
									v-model="documentForm.name"
									placeholder="Ingrese nueva categoría ..."
								/>
							</b-form-group>
						</b-col>
					</template>

					<b-col
						cols="12"
						v-else
					>
						<label for="description">
							<strong>Descripción</strong>
						</label>
						<b-form-group>
							<quill-editor
								id="description"
								v-model="documentForm.description"
								:options="snowOption"
								placeholder="Ingrese la descripción aquí ..."
								:disabled="requestStateId !== EN_EDICION"
								:readonly="requestStateId !== EN_EDICION"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>

			<template #modal-footer>
				<b-button
					v-if="requestStateId === EN_EDICION"
					@click="saveDocument()"
					variant="outline-primary"
				>{{ isEconomic ? 'ACTUALIZAR' : isEditing ? 'EDITAR' : 'AGREGAR'}}</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="closeDocumentModal()"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import { ref, onUnmounted, onMounted } from "@vue/composition-api"
import { RequestStates } from "@core/utils/data"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import tabRequirementStoreModule from "./tabRequirementStoreModule"
import useNotifications from "@notifications"
import store from "@/store"

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonLoading,

		quillEditor
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			require: true
		},
		economicProposal: {
			type: Object,
			default: () => ({}),
			require: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()
		const { EN_EDICION } = RequestStates

		const SIG_ID = 2
		const TEC_ID = 3
		//REFS
		const proposalWeightings = ref([])
		const economicProposal = ref(props.economicProposal)
		const refProposalModal = ref(null)
		const refDocumentModal = ref(null)
		const isEconomic = ref(false)
		const isSig = ref(false)
		const isTechnical = ref(false)
		const documentForm = ref({})
		const formData = ref({
			economic: {},
			sigDocuments: [],
			tecDocuments: []
		})
		const weight = ref(null)
		const documentVisible = ref(true)
		const isEditing = ref(false)
		const processing = ref(false)

		const deletedDocuments = ref([])
		const indexOfDocument = ref(null)
		const snowOption = ref({
			theme: "snow"
		})

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-base"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				tabRequirementStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-request-base/fetchRequestProposalDocuments", {
					request_id: props.requestId
				})
				.then((response) => {
					setDocumentsData(response.data)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})

			store
				.dispatch("app-request-base/fetchRequestProposalWeightings", {
					request_id: props.requestId
				})
				.then((response) => {
					setWeightingsData(response.data)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const setDocumentsData = (data) => {
			const sigDocuments = []
			const tecDocuments = []

			data.documents.forEach((item) => {
				if (item.proposal_type_id === SIG_ID) {
					sigDocuments.push(item)
				}
				if (item.proposal_type_id === TEC_ID) {
					tecDocuments.push(item)
				}
			})

			formData.value.economic = economicProposal.value
			formData.value.sigDocuments = sigDocuments
			formData.value.tecDocuments = tecDocuments
		}
		const setWeightingsData = (data) => {
			proposalWeightings.value = data.proposalWeightings
		}

		onMounted(() => {
			getData()
		})

		const openProposalModal = (
			economic = false,
			sig = false,
			technical = false
		) => {
			if (economic) {
				isEconomic.value = economic
				weight.value = proposalWeightings.value[0].weight
			}
			if (sig) {
				isSig.value = sig
				weight.value = proposalWeightings.value[1].weight
			}
			if (technical) {
				isTechnical.value = technical
				weight.value = proposalWeightings.value[2].weight
			}

			refProposalModal.value.show()
		}

		const updateProposalWeighting = () => {
			const id = isEconomic.value
				? proposalWeightings.value[0].id
				: isSig.value
				? proposalWeightings.value[1].id
				: proposalWeightings.value[2].id

			const payload = { weight: weight.value, request_id: props.requestId }

			store
				.dispatch("app-request-base/updateRequestProposalWeighting", {
					id,
					payload
				})
				.then((response) => {
					const index = isEconomic.value ? 0 : isSig.value ? 1 : 2
					proposalWeightings.value[index].weight = parseFloat(
						weight.value
					).toFixed(2)

					resetModal()
					refProposalModal.value.hide()
					toastNotification(response.data.message)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const openDocumentModal = (
			economic = false,
			sig = false,
			technical = false
		) => {
			if (economic) {
				isEconomic.value = economic
				documentForm.value.description = formData.value.economic.description
			}
			isSig.value = sig
			isTechnical.value = technical

			refDocumentModal.value.show()
		}

		const closeDocumentModal = () => {
			resetModal()

			refDocumentModal.value.hide()
		}

		const editDocument = (index, sig = false, technical = false) => {
			isEditing.value = true
			indexOfDocument.value = index
			isSig.value = sig
			isTechnical.value = technical

			let document = null
			if (isSig.value) document = formData.value.sigDocuments[index]
			if (isTechnical.value) document = formData.value.tecDocuments[index]
			if (!document.is_new) documentVisible.value = false

			documentForm.value.name = document.name
			documentForm.value.weight = parseFloat(document.weight).toFixed(2)

			refDocumentModal.value.show()
		}

		const removeDocument = (index, sig = false, technical = false) => {
			let document = null

			if (sig) {
				document = formData.value.sigDocuments[index]
				formData.value.sigDocuments.splice(index, 1)
			}
			if (technical) {
				document = formData.value.tecDocuments[index]
				formData.value.tecDocuments.splice(index, 1)
			}
			if (document && document.id) {
				deletedDocuments.value.push(document)
			}
		}

		const saveDocument = async () => {
			if (
				!isEconomic.value &&
				(!documentForm.value.name || !documentForm.value.weight)
			) {
				toastNotification("Complete los campos", false)
				return
			}

			if (isEconomic.value && !documentForm.value.description) {
				toastNotification("Complete el campo", false)
				return
			}

			if (isEconomic.value) {
				const payload = {
					description: documentForm.value.description,
					request_id: props.requestId
				}
				store
					.dispatch("app-request-economic/updateRequestEconomicProposal", {
						id: economicProposal.value.id,
						payload
					})
					.then((response) => {
						formData.value.economic.description = payload.description
						refDocumentModal.value.hide()
						toastNotification(response.data.message)
					})
					.catch((error) => {
						toastNotification(error.response.data.message, false)
					})
			}

			if (isEditing.value) {
				if (isSig.value) {
					formData.value.sigDocuments[indexOfDocument.value].name = (
						documentForm.value.name || ""
					).toUpperCase()
					formData.value.sigDocuments[indexOfDocument.value].weight =
						parseFloat(documentForm.value.weight).toFixed(2)
				}
				if (isTechnical.value) {
					formData.value.tecDocuments[indexOfDocument.value].name = (
						documentForm.value.name || ""
					).toUpperCase()
					formData.value.tecDocuments[indexOfDocument.value].weight =
						parseFloat(documentForm.value.weight).toFixed(2)
				}

				resetModal()
				refDocumentModal.value.hide()
				return
			}

			const data = {
				name: (documentForm.value.name || "").toUpperCase(),
				weight: parseFloat(documentForm.value.weight).toFixed(2),
				is_required: true,
				is_new: true
			}

			if (isSig.value)
				formData.value.sigDocuments.push({ ...data, proposal_type_id: SIG_ID })
			if (isTechnical.value)
				formData.value.tecDocuments.push({ ...data, proposal_type_id: TEC_ID })

			resetModal()
			refDocumentModal.value.hide()
		}

		const resetModal = () => {
			isEconomic.value = false
			isSig.value = false
			isTechnical.value = false
			isEditing.value = false
			documentVisible.value = true
			indexOfDocument.value = null
			weight.value = null
			documentForm.value = {
				name: null,
				weight: null,
				descreiption: null
			}
		}

		const toggleRequired = (document) => {
			document.is_required = !document.is_required
		}

		const saveInformation = async () => {
			processing.value = true

			const payload = {
				request_id: props.requestId,
				deletedDocuments: deletedDocuments.value,
				selectedDocuments: [
					...formData.value.sigDocuments,
					...formData.value.tecDocuments
				]
			}
			store
				.dispatch("app-request-base/updateRequestProposalDocuments", {
					payload
				})
				.then((response) => {
					toastNotification(response.data.message)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
				.finally(() => {
					processing.value = false
				})
		}

		return {
			//REFS
			refProposalModal,
			refDocumentModal,
			isTechnical,
			isEconomic,
			isSig,
			documentForm,
			formData,
			weight,
			documentVisible,
			isEditing,
			processing,

			snowOption,

			//METHODS
			toggleRequired,

			openProposalModal,
			updateProposalWeighting,
			openDocumentModal,
			closeDocumentModal,
			editDocument,
			removeDocument,
			saveDocument,
			saveInformation,
			proposalWeightings,

			EN_EDICION
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>