import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchRequestEconomicTemplate(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/request-economic-templates/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateRequestEconomicTemplate(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {

				let formData = new FormData();

                formData.append("file", payload.file);
                formData.append("request_id", payload.request_id);
                formData.append("data", JSON.stringify(payload.data));

				axios
					.put(`/request-economic-templates/${id}/template`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateRequestEconomicProposal(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/request-economic-templates/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}
	}
}